.table-container {
    padding: 0px;
    margin: 0; /* Remove margins */
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0px;
  }
  
  thead {
    background-color: #0071A6;
    color: white;
  }
  
  th, td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  
  tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  th {
    cursor: pointer;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #007bff;
    color: #fff;
  }
  
  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .pagination .page-numbers {
    display: flex;
  }
  
  .pagination .page-numbers button {
    margin: 0;
  }
  