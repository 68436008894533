.card {
    display: flex;
    gap: 20px; /* Space between cards */
    flex-wrap: wrap; /* Wrap cards to next line on smaller screens */
    justify-content: center; /* Center cards horizontally */
    padding: 20px; /* Padding around the card container */
    width: 200px;
    height: 100px;
    /* border-radius: 20px; */
}
.div1,.div2{
    display: flex;
    gap:20px;
    margin-bottom:20px;
}

