.header {
  width: 100%;
  top: 0;
  left: 0;
  background-color: #0071A6;
  z-index: 2;
  padding: 0 20px;
  display: flex;
  justify-content: space-between; /* Space between elements */
  /* align-items: center; */
}

.profile-icon-container {
  margin-left: auto; 
}

.profile-icon {
  color: #ffffff;
  cursor: pointer;
  font-size: 100px;
}

.profile-popup {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 250px;
  z-index: 1000;
}

.profile-container {
  text-align: left;
  color: #333;
}

.profile-container h1 {
  font-size: 18px;
  margin-bottom: 10px;
}

.profile-container p {
  margin: 10px 0;
  font-size: 14px;
}
