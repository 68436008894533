/* Centering the login container in the viewport */
body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    background-color: #f0f0f0;
}

/* Styling for the login container */
.login-container {
    max-width: 400px;
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #ffffff; /* White background for the container */
}

/* Center the heading text and give it a dark color */
.login-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

/* Styling for input fields */
.login-container input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
}

/* Button styling with hover effect */
.login-container button {
    width: 100%;
    padding: 10px;
    background-color: #007bff; /* Primary blue color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

.login-container button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

/* Styling for error and success messages */
.login-container p {
    margin: 10px 0;
    text-align: center; /* Centering the text for messages */
}

/* Specific styling for error message */
.login-container p[style*="color: red"] {
    color: red;
}

/* Specific styling for success message */
.login-container p[style*="color: green"] {
    color: green;
}
