/* General dashboard layout */
.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full height */
  width: 100vw;
  margin: 0;
  padding: 0;
  margin-top: 50px;
}

/* Sidebar styling */
.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #0071A6;
  padding-top: 20px;
  border-right: 1px solid #ddd;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: transform 0.3s ease;
}

.sidebar.closed {
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

/* Main content area */
.main-content {
  margin-left: 250px;
  margin-top: 0; /* Adjust as needed */
  padding: 20px;
  overflow-y: auto;
  transition: margin-left 0.3s ease;
}

/* Header styling */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #0071A6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
  padding: 0;
  margin: 0;
}

/* Ensure no additional margin on body */
body {
  margin: 0;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .sidebar {
    width: 200px;
  }

  .main-content {
    margin-left: 0;
  }
}
